import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import "../styles/feedback.scss";
import "../styles/contact-us.scss";

import Button from "../components/button/button";

import Layout from "../layouts"

import Header from "../components/header/header"

// markup
const Feedback = () => {

    const data = useStaticQuery(graphql`
        {
    
            strapiFeedback {
                PageComponents
                Seo {
                  metaDescription
                  metaTitle
                }
              }
              allFile {
                edges {
                  node {
                    publicURL
                    id
                    name
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
        }
    `)





    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        return (imgUrl)
    }


    // const { siteUrl: url } = useSiteMetadata()

    // let richSnippetData = {
    //   '@context': "https://schema.org",
    //   "@type": "Organization",
    //   name: `${data.strapiSiteSettings.SiteTitle}`,
    //   url: `${url}`,
    //   logo: `${url + data.strapiSiteSettings.LogoDark.localFile.publicURL}`,
    //   sameAs: [data.strapiSiteSettings.SocialLinks.FacebookURL, data.strapiSiteSettings.SocialLinks.TwitterURL, data.strapiSiteSettings.SocialLinks.LinkedinURL, data.strapiSiteSettings.SocialLinks.YoutubeURL]
    // }

    const [msgError, setMsgError] = useState(false);

    const [form, setForm] = useState({
        pageType: "feedback",
        name: "",
        phone: "",
        email: "",
        feedback: "",
    });

    const onUpdateField = e => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
    };

    const [error, setError] = useState({
        nameError: '',
        phoneError: '',
    });

    const validate = async (e) => {

        let nameError = "";
        let phoneError = "";
        let isNotValid = false;

        if (form.name === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }
        if (form.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;
        }

        setError({ ...error, nameError: nameError, phoneError: phoneError });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const submitForm = async (e) => {

        e.preventDefault();

        let value = await validate();
        console.log(value, "value");
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOsdjfh7M2ZeaWrnn1',
            FormData: JSON.stringify(form),
            PageType: 'feedback'
        };

        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))


        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(form).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }



    return (
        <Layout SEOTitle={data.strapiFeedback.Seo.metaTitle} SEODescription={data.strapiFeedback.Seo.metaDescription} footerDisclaimer={data.strapiFeedback.FooterDisclaimer}>

            {data.strapiFeedback?.PageComponents?.map((item, index) => {
                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>
                                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                <Header key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTALabel={item.FirstCTALabel}
                                    FirstCTAURL={item.FirstCTAURL}
                                    Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    BackgroundColor={item.BackgroundColorHeader}
                                ></Header>
                            </>

                        )

                    default:
                        return ("")
                }
            })}







            <div className="feedback-page-container">
                <div className="padding-form uk-flex-center uk-grid" uk-grid="true">
                    <div id="feedback-form">

                        <form className="uk-grid" uk-grid="true" onSubmit={submitForm}>
                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width">
                                <label className="label-text uk-form-label">Name*</label>
                                <input
                                    className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    value={form.name}
                                    onChange={onUpdateField} />
                            </div>

                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width no-padding-left">
                                <label className="label-text uk-form-label">Telephone*</label>
                                <input
                                    className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder=""
                                    aria-label="50"
                                    name="phone"
                                    value={form.phone}
                                    onChange={onUpdateField} />
                            </div>

                            <div className="uk-width-1-1" id="input-margin-100">
                                <label className="label-text uk-form-label">Email</label>
                                <input
                                    className="uk-input"
                                    type="text"
                                    placeholder=""
                                    name="email"
                                    value={form.email}
                                    onChange={onUpdateField} />
                            </div>

                            <div className="uk-width-1-1" id="input-margin-100">
                                <label className="label-text uk-form-label" for="form-stacked-select">Feedback</label>
                                <textarea
                                    className="uk-textarea"
                                    rows="5"
                                    placeholder=""
                                    aria-label="Textarea"
                                    name="feedback"
                                    value={form.feedback}
                                    onChange={onUpdateField}></textarea>
                            </div>

                            <div className="btn">
                                <Button
                                    isSubmit="true"
                                    btnType="secondary"
                                    label={`${loading == false ? 'Send Feedback' : 'Loading...'}`}
                                    additionalClass="feedback-btn"
                                />

                                {
                                    msgError ? <p className="error-msg" style={{ marginLeft: "30px" }}>One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }
                            </div>


                        </form>

                    </div>
                </div>
            </div >

        </Layout >
    )
}

export default Feedback